<template>
  <div class="max-w-full overflow-x-scroll mb-12 sm:mx-2 md:max-w-7xl">
    <h1 class="text-2xl font-medium mb-0">Cash Drops</h1>
    <div v-if="!loading && errorMessage">
      <Alert variant="red" icon="exclamation">Error loading Cash Drops: {{ errorMessage }}</Alert>
    </div>
    <GenericTable
      v-if="!errorMessage"
      :columns="columns"
      :data="data"
      :loading="loading"
      :actions="true"
      class="cash-drop"
    >
      <template #deviceName="{ row }">
        <router-link :to="{ path: `device/${row.deviceId}` }">
          <span class="text-gray-900 underline">{{ row.deviceName }}</span>
        </router-link>
      </template>
      <template #ticketSellerName="{ row }">
        <SellerNameChangeModal :row="row" @triggerRefresh="refreshTable" />
      </template>
      <template #latestDrop="{ row }">
        <p :id="`last-drop-${row.deviceId}`" class="dotted-underline">
          {{ row.latestDrop ? format(new Date(row.latestDrop), 'yyyy-MM-dd') : '' }}
        </p>
        <b-tooltip :target="`last-drop-${row.deviceId}`" triggers="hover" v-if="row.latestDrop">
          {{
            formatDateTime(parseISO(row.latestDrop), {
              format: 'dateTime',
              timeZone: sessionUserTimeZone,
              displayTimeZone: true
            })
          }}
        </b-tooltip>
      </template>
      <template #salesTotalCents="{ row }">
        <p class="mb-0">{{ formatCurrency(row.salesTotalCents / 100) }}</p>
      </template>
      <template #startingFloat="{ row }">
        <CashDropAddToFloatModal
          :row="row"
          :eventId="eventId"
          @triggerRefresh="refreshTable"
          v-if="!isInteractionDisabled(row)"
        />
        <div v-else>{{ formatCurrency(row.startingFloat / 100) }}</div>
      </template>
      <template #balanceCents="{ row }">
        <CashDropActivityModal
          :row="row"
          :interactionsDisabled="isInteractionDisabled(row)"
          @cash-drop-updated="markActivityModified"
          @cash-drop-deleted="markActivityModified"
          @modal-closed="refreshIfActivityModified"
        />
      </template>
      <template #actions="{ row }" class="table-cell">
        <CashDropDropCashModal
          :row="row"
          :eventId="eventId"
          @triggerRefresh="refreshTable"
          :disabled="isInteractionDisabled(row)"
          v-b-tooltip.hover
          :title="!row.ticketSellerName ? 'Seller name missing' : ''"
        />
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import CashDropActivityModal from '@/components/modals/CashDropActivityModal';
import CashDropAddToFloatModal from '@/components/modals/CashDropAddToFloatModal';
import CashDropDropCashModal from '@/components/modals/CashDropDropCashModal';
import SellerNameChangeModal from '@/components/modals/SellerNameChangeModal';
import ReportService from '@/lib/report-service-v2';
import { format } from 'date-fns';

export default {
  props: {
    eventId: {
      type: String,
      required: true
    },
    organizationId: {
      type: String,
      required: true
    },
    eventClosed: {
      type: Boolean,
      required: true
    }
  },
  components: {
    GenericTable,
    CashDropActivityModal,
    CashDropAddToFloatModal,
    CashDropDropCashModal,
    SellerNameChangeModal
  },
  data() {
    return {
      columns: [
        {
          name: 'deviceName',
          label: 'Device name',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'ticketSellerName',
          label: 'Seller Name',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'latestDrop',
          label: 'Last Activity',
          sortable: false,
          classes: 'w-auto'
        },
        {
          name: 'salesTotalCents',
          label: 'Total Cash Sales',
          sortable: false,
          classes: 'w-auto',
          isCurrency: true
        },
        {
          name: 'startingFloat',
          label: 'Starting Float',
          sortable: false,
          classes: 'w-auto',
          isCurrency: true
        },
        {
          name: 'balanceCents',
          label: 'Balance',
          sortable: false,
          classes: 'w-auto',
          isCurrency: true
        }
      ],
      data: [],
      format: format,
      errorMessage: null,
      loading: true,
      activityModified: false
    };
  },
  methods: {
    async fetchCashDrops() {
      try {
        const params = {
          eventId: this.eventId
        };
        const response = await ReportService.cashDropSummaryReport(params);
        this.data = response.data;
        this.loading = false;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async refreshTable() {
      this.loading = true;
      await this.fetchCashDrops();
    },
    isInteractionDisabled(row) {
      return this.eventClosed || !row.ticketSellerName;
    },
    markActivityModified() {
      this.activityModified = true;
    },
    async refreshIfActivityModified() {
      if (this.activityModified) {
        await this.refreshTable();
        this.activityModified = false;
      }
    }
  },
  async mounted() {
    await this.fetchCashDrops();
  }
};
</script>

<style lang="scss">
.dotted-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}
</style>
